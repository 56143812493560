/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./Sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ menuItems }) => {
    const location = useLocation();
console.log("menuItems: ", menuItems);
    return (
        <div className="Sidebar">
        {!menuItems || menuItems.length === 0 ? (
            <div className="warning">No menu items available</div>
        ) : (
            menuItems.map((item, index) => (
                <NavLink
                    to={item.path}
                    key={index}
                    className={
                        location.pathname === "/platform" + item.path
                            ? "menu-item nav-item active"
                            : "menu-item nav-item"
                    }
                >
                    <div className="icon">{item.icon}</div>
                    <div className="title">{item.title}</div>
                </NavLink>
                ))
            )}
        </div>
    );
};

{
    /* <NavLink to="page1" activeClassName="active">
Page 1
</NavLink> */
}
Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
