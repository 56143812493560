/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import "./AppHeader.scss";

import LoginButton from "../Login/LoginButton/LoginButton";

const AppHeader = ({ user, recordUser }) => {
    const location = useLocation();
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const togglePanel = () => {
        console.log("togglePanel");
        setIsPanelOpen(!isPanelOpen);
    };

    const renderMenu = () => {
        console.log("renderMenu");
        return (
            <nav className="navbar navbar-expand-lg">
                <button
                    id="menu-toggler"
                    className={`navbar-toggler menu-toggler ${
                        isPanelOpen ? "close" : ""
                    }`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={togglePanel}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`sliding-panel ${isPanelOpen ? "open" : ""}`}>
                    <div className="panel-header">
                        <button
                            id="close-panel"
                            className="close-btn"
                            onClick={togglePanel}
                        >
                            ✖ Close
                        </button>
                    </div>
                    <div className="navbar-nav">
                        <NavLink
                            to="/"
                            onClick={togglePanel}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/solutions"
                            onClick={togglePanel}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Solutions
                        </NavLink>
                        <NavLink
                            to="/case-study"
                            onClick={togglePanel}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Case study
                        </NavLink>
                        <NavLink
                            to="/about"
                            onClick={togglePanel}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/contact-us"
                            onClick={togglePanel}
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Contact us
                        </NavLink>
                    </div>

                </div>
                <div
                    className="collapse navbar-collapse"
                    id="navbarNavAltMarkup"
                >
                    <div className="navbar-nav">
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/solutions"
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Solutions
                        </NavLink>
                        <NavLink
                            to="/case-study"
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Case study
                        </NavLink>
                        <NavLink
                            to="/about"
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/contact-us"
                            className={({ isActive }) =>
                                isActive
                                    ? "nav-item nav-link active"
                                    : "nav-item nav-link"
                            }
                        >
                            Contact us
                        </NavLink>
                    </div>
                </div>
            </nav>
        );
    };

    const renderButton = () => {
        return <LoginButton user={user} recordUser={recordUser}></LoginButton>;
    };

    // A function that returns true if the location doesn't contain /platform or /onboarding
    const isNotPlatformOrOnboarding = () => {
        return (
            !location.pathname.includes("/platform") ||
            !location.pathname.includes("/onboarding")
        );
    };

    // A function that returns true when the location is /
    const isHome = () => {
        return (
            !location.pathname.includes("/platform") &&
            !location.pathname.includes("/documentation") &&
            !location.pathname.includes("/onboarding") &&
            !location.pathname.includes("/check")
        );
    };

    // a function if the location contains platform  and !!user or if the location contains check
    const isPlatformOrCheck = () => {
        return (
            (location.pathname.includes("/platform") && !!user) ||
            location.pathname.includes("/check")
        );
    };

    return (
        <div className="AppHeader">
            <header>
                <div>
                    <div className="logo-container">
                        <Link to="./">
                            <img
                                src="/logo.png"
                                className="app-logo img-fluid"
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="menu-spacer"></div>

                    {(() => {
                        let menu;
                        let button;
                        console.log("location.pathname: ", location.pathname);
                        if (isHome()) {
                            console.log("isHome");
                            menu = renderMenu();
                        }
                        if (isPlatformOrCheck()) {
                            console.log("isPlatformOrOnboardingOrCheck");
                            button = renderButton();
                        }
                        const content = (
                            <>
                                {menu}
                                {button}
                            </>
                        );

                        return <>{content}</>;

                    })()}

                </div>
            </header>
        </div>
    );
};

AppHeader.propTypes = {};

AppHeader.defaultProps = {};

export default AppHeader;
