/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./About.scss";

const About = () => (
    <div className="About">
        <h1>About</h1>
        <h3>This page is under construction</h3>
        You can find more information on{" "}
        <a href="https://www.linkedin.com/in/vince-beligent/">LinkedIn.</a>
    </div>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
