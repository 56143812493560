/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./CaseStudy.scss";

const CaseStudy = () => (
    <div className="CaseStudy">
        <h1>Case study</h1>
        <h3>This page is under construction</h3>
        <p>
            While we are preparing more content, you can find more information on <a href="/">Home page</a>
        </p>
    </div>
);

CaseStudy.propTypes = {};

CaseStudy.defaultProps = {};

export default CaseStudy;
