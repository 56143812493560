/**
 * Beligent
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";
import "./App.scss";

import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";

import AppHeader from "./components/AppHeader/AppHeader";
import AppFooter from "./components/AppFooter/AppFooter";
import HomePage from "./components/HomePage/HomePage";
import CheckApp from "./components/CheckApp/CheckApp/CheckApp.tsx";

import Platform from "./components/Platform/Platform/Platform.tsx";

import Solutions from "./components/pages/Solutions/Solutions.js";
import CaseStudy from "./components/pages/CaseStudy/CaseStudy.js";
import About from "./components/pages/About/About.js";
import ContactUs from "./components/pages/ContactUs/ContactUs.js";
import TermsOfUse from "./components/pages/TermsOfUse/TermsOfUse.js";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy.js";
import Impressum from "./components/pages/Impressum/Impressum.js";
import Onboarding from "./components/UserOnboarding/Onboarding/Onboarding.js";
import ScrollToTop from "./lib/ScrollToTop";

/**
 * @function NoMatch
 * @description Renders the 404 page when the user tries to access a page that does not exist
 * @returns {JSX.Element} The 404 page
 */
function NoMatch() {
    return (
        <div className="screen-404">
            <h1 className="">Page not found</h1>
            <p>
                <Link to="/">Back to home page</Link>
            </p>
        </div>
    );
}

function App() {
    const getConnectedUserCallBack = (user) => {
        if (
            (!user || !user.publicAddress) &&
            user &&
            user.publicAddress !== ""
        ) {
            // console.log("User from connect App.js: ");
            setUser(user);
        }
    };

    // moved to loginform but may be necessary to jeep the one in header alive
    const [user, setUser] = useState(null);
    const recordUser = (loggedInUser) => {
        console.log("recordUser: ");
        console.log(loggedInUser);
        // Callback for successful login from popup
        setUser(loggedInUser);
        saveUserToSession(loggedInUser);
    };
    const saveUserToSession = (user) => {
        const timestamp = new Date().getTime(); // Get current time in milliseconds
        localStorage.setItem("_BLGT_user", JSON.stringify(user)); // Store user object
        localStorage.setItem("_BLGT_userTimestamp", timestamp); // Store timestamp
    };
    const checkSessionTimeout = () => {
        console.log("Checking session timeout");
        const user = JSON.parse(localStorage.getItem("_BLGT_user"));
        const timestamp = localStorage.getItem("_BLGT_userTimestamp");

        if (!user || !timestamp) {
            // If no user or timestamp exists, session is invalid
            return null;
        }

        const currentTime = new Date().getTime();
        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

        if (currentTime - timestamp > TEN_MINUTES) {
            // If more than 10 minutes have passed, clear the session
            localStorage.removeItem("_BLGT_user");
            localStorage.removeItem("_BLGT_userTimestamp");
            console.log("Session expired");
            return null;
        }

        // If session is still valid, return the user
        return user;
    };
    useEffect(() => {
        const savedUser = checkSessionTimeout();
        if (savedUser) {
            setUser(savedUser);
        }
    }, []);
    // end login user stuff

    // This is temporary as we don't retrieve the user's nfts on the blockchain.
    // We assume it is empty if page reloaded.
    // probably not used anymore but could be useful.
    // localStorage.setItem('_BLGT_minting', JSON.stringify(false));
    // localStorage.setItem('_BLGT_minted', JSON.stringify(false));

    function Layout() {
        return (
            <div className="app-container">
                <ScrollToTop />
                <AppHeader user={user} recordUser={recordUser} />
                <Outlet />
                <AppFooter onSubmit={getConnectedUserCallBack} />
            </div>
        );
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage user={user} />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/case-study" element={<CaseStudy />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route
                        path="/check/:address?/:id?"
                        element={<CheckApp user={user} />}
                    />

                    {/* Delegating /platform routing to the Platform component */}
                    <Route
                        path="/platform/*"
                        element={<Platform user={user} />}
                    />
                    <Route
                        path="/platform"
                        element={<Navigate to="/platform/dashboard" />}
                    />

                    {/* Delegating /platform routing to the Platform component */}
                    <Route
                        path="/onboarding/*"
                        element={<Onboarding user={user} />}
                    />
                    <Route
                        path="/onboarding"
                        element={<Navigate to="/onboarding/registration-end" />}
                    />

                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
