/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";
import "./Display.scss";

import { isIpfsUrl } from "../../../lib/utils.js";

// Props can deliver a valid URL, an OpenSea URL or a contract address and token ID.
const Display = ({ resultObject }) => {
    console.log(resultObject);
    const [imageUrl, setImageUrl] = useState("");
    const [svgData, setSvgData] = useState("");

    const [isCertificateOpen, setCertificateIsOpen] = useState(true);
    const [areDetailsOpen, setDetailsAreOpen] = useState(false);
    const [isMetadataOpen, setMetadataIsOpen] = useState(false);

    const toggleCertificate = (event) => {
        setCertificateIsOpen((current) => !current);
    };

    const toggleDetails = (event) => {
        setDetailsAreOpen((current) => !current);
    };

    const toggleMetadata = (event) => {
        setMetadataIsOpen((current) => !current);
    };

    const violaData = {
        tokenUri:
            "https://ipfs.io/ipfs/QmXgnk6nxadfmvLPnEZbsXJ8tMLe1qXv6APaQxRvPZCZ4E",
        metadata: {
            name: "Viola 41 Modello Capicchioni by Alessandro Milani",
            description:
                "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
            image: "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
            attributes: [
                { value: "Viola", trait_type: "Instrument" },
                { value: "41 cm", trait_type: "Size" },
                { value: "2024", trait_type: "Year of Construction" },
                {
                    value: "Boca (NO), Italy",
                    trait_type: "Place of Construction",
                },
                { value: "Alessandro Milani", trait_type: "Maker" },
                {
                    value: "Inspired by Marino Capicchioni",
                    trait_type: "Model",
                },
                {
                    value: "Italian spruce from Val di Fiemme",
                    trait_type: "Top Wood",
                },
                {
                    value: "Straight and regular grain, narrow at the center",
                    trait_type: "Grain Pattern",
                },
                { value: "Balkan maple, two pieces", trait_type: "Back Wood" },
                {
                    value: "Medium-width flamed, fades towards edges",
                    trait_type: "Flame Figure",
                },
                {
                    value: "Spirit varnish, bright slightly dark red, transparent",
                    trait_type: "Varnish",
                },
                {
                    value: "Woods seasoned for at least 8 years",
                    trait_type: "Natural Seasoning",
                },
                {
                    value: "Natural origin, extracted with Soxhlet apparatus",
                    trait_type: "Dyes",
                },
                { value: "Rosewood, Padauk", trait_type: "Colored Essences" },
            ],
            external_url:
                "https://amethyst-semantic-clam-109.mypinata.cloud/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
        },
    };

    /*

{
  "name": "Viola 41 Modello Capicchioni by Alessandro Milani",
  "description": "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
  "certificate": {
    "instrument": "Viola",
    "size": "41 cm",
    "yearOfConstruction": "2024",
    "placeOfConstruction": "Boca (NO), Italy",
    "maker": "Alessandro Milani",
    "model": "Inspired by Marino Capicchioni",
    "topWood": "Italian spruce from Val di Fiemme",
    "grainPattern": "Straight and regular grain, narrow at the center",
    "backWood": "Balkan maple, two pieces",
    "flameFigure": "Medium-width flamed, fades towards edges",
    "varnish": "Spirit varnish, bright slightly dark red, transparent",
    "naturalSeasoning": "Woods seasoned for at least 8 years",
    "dyes": "Natural origin, extracted with Soxhlet apparatus",
    "coloredEssences": "Rosewood, Padauk"
  },
  "details": {
    "contractAddress": "0x68e7F29a8Ac8a8dCB5E3C5f692cA291d2f65E386",
    "imageAddress": "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX"
  },
  "metadata": {
    "contract": {
      "address": "0x68e7F29a8Ac8a8dCB5E3C5f692cA291d2f65E386",
      "tokenType": "ERC721",
      "contractDeployer": "0xE47cfD37C23136e3a6927E85603faa85D78Fcc4B",
      "deployedBlockNumber": 12366229,
      "openSeaMetadata": {},
      "spamClassifications": []
    },
    "tokenId": "0",
    "tokenType": "ERC721",
    "name": "Viola 41 Modello Capicchioni by Alessandro Milani",
    "description": "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
    "tokenUri": "https://alchemy.mypinata.cloud/ipfs/QmXgnk6nxadfmvLPnEZbsXJ8tMLe1qXv6APaQxRvPZCZ4E",
    "image": {
      "cachedUrl": "https://nft-cdn.alchemy.com/matic-amoy/b77fae159308cd963b19c097566aa40b",
      "thumbnailUrl": "https://res.cloudinary.com/alchemyapi/image/upload/thumbnailv2/matic-amoy/b77fae159308cd963b19c097566aa40b",
      "pngUrl": "https://res.cloudinary.com/alchemyapi/image/upload/convert-png/matic-amoy/b77fae159308cd963b19c097566aa40b",
      "contentType": "image/png",
      "size": 188812,
      "originalUrl": "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX"
    },
    "raw": {
      "tokenUri": "https://ipfs.io/ipfs/QmXgnk6nxadfmvLPnEZbsXJ8tMLe1qXv6APaQxRvPZCZ4E",
      "metadata": {
        "name": "Viola 41 Modello Capicchioni by Alessandro Milani",
        "description": "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
        "image": "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
        "attributes": [
          {
            "value": "Viola",
            "trait_type": "Instrument"
          },
          {
            "value": "41 cm",
            "trait_type": "Size"
          },
          {
            "value": "2024",
            "trait_type": "Year of Construction"
          },
          {
            "value": "Boca (NO), Italy",
            "trait_type": "Place of Construction"
          },
          {
            "value": "Alessandro Milani",
            "trait_type": "Maker"
          },
          {
            "value": "Inspired by Marino Capicchioni",
            "trait_type": "Model"
          },
          {
            "value": "Italian spruce from Val di Fiemme",
            "trait_type": "Top Wood"
          },
          {
            "value": "Straight and regular grain, narrow at the center",
            "trait_type": "Grain Pattern"
          },
          {
            "value": "Balkan maple, two pieces",
            "trait_type": "Back Wood"
          },
          {
            "value": "Medium-width flamed, fades towards edges",
            "trait_type": "Flame Figure"
          },
          {
            "value": "Spirit varnish, bright slightly dark red, transparent",
            "trait_type": "Varnish"
          },
          {
            "value": "Woods seasoned for at least 8 years",
            "trait_type": "Natural Seasoning"
          },
          {
            "value": "Natural origin, extracted with Soxhlet apparatus",
            "trait_type": "Dyes"
          },
          {
            "value": "Rosewood, Padauk",
            "trait_type": "Colored Essences"
          }
        ],
        "external_url": "https://amethyst-semantic-clam-109.mypinata.cloud/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX"
      }
    },
    "mint": {},
    "timeLastUpdated": "2024-09-24T15:23:45.618Z"
  }
}





*/

    const renderImage = (imageSrc: string) => {
        return (
            <div className="nft-block-img">
                {/* <img src={imageSrc} /> */}
                <img src={metadata.image.originalUrl} /> {/* temp hardcoding for stable demo */}
            </div>
        );
    };

    const renderSvgImage = () => {
        return (
            <div
                className="nft-block-img"
                dangerouslySetInnerHTML={{ __html: svgData }}
            ></div>
        );
    };

    const renderNftMainData = () => {
        return (
            <div className="card">
                <div className="row mb-4">
                    <div className="col-4 text-muted">Name:</div>
                    {/* <div className="col-8 nft-title">{nftData.nftName}</div> */}
                    <div className="col-8 nft-title">{metadata.name}</div> {/* temp hardcoding for stable demo */}
                </div>
                <div className="row mb-4">
                    {/*     <div className="col-4 text-muted">Collection:</div> */}
                    <div className="col-8 nft-collection">
                        {nftData.collection}
                    </div>
                </div>
                {/* <div className="row mb-4">
                    <div className="col-4 text-muted">Floor price:</div>
                    <div className="col-8 nft-floor-price">
                        {nftData.floorPrice}
                    </div>
                </div> */}
                <div className="row mb-4">
                    <div className="col-4 text-muted">Description:</div>
                    {/* <div className="col-8">{nftData.description}</div> */}
                    <div className="col-8">{metadata.description}</div> {/* temp hardcoding for stable demo */}
                </div>
                {/* <div className="row mb-4">
                    <div className="col-4 text-muted">Metadata storage:</div>
                    <div className="col-8">
                        {nftData.metadataStorageIsDecentralized
                            ? "Decentralized, safest"
                            : "Traditional web storage, potentially unsafe!"}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Image storage:</div>
                    <div className="col-8">
                        {nftData.imageStorageIsDecentralized
                            ? "Decentralized, safest"
                            : "Traditional web storage, potentially unsafe!"}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4 text-muted">Links:</div>
                    <div className="col-8">OpenSea, Etherscan</div>
                </div> */}
            </div>
        );
    };

    const renderPassport = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                isCertificateOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleCertificate}
                        >
                            Certificate
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            isCertificateOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            {violaData.metadata.attributes.map((attr) => (
                                <div className="row mb-4" key={attr.trait_type}>
                                    {" "}
                                    <div className="col-5 text-muted">
                                        {" "}
                                        {attr.trait_type}:{" "}
                                    </div>{" "}
                                    <div className="col-7"> {attr.value} </div>{" "}
                                </div>
                            ))}

                            {/* 
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate creator address:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .nftCreatorAddress
                                        : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate creator name:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .nftCreatorName
                                        : "N/A"}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-5 text-muted">
                                    Certificate timestamp:
                                </div>
                                <div className="col-7">
                                    {resultObject["certificateData"]
                                        ? resultObject["certificateData"]
                                              .timestamp +
                                          "(" +
                                          resultObject["certificateData"]
                                              .issueDate +
                                          ")"
                                        : "N/A"}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNftDetails = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                areDetailsOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleDetails}
                        >
                            Details
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            areDetailsOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row mb-4">
                                <div className="col-3 text-muted">
                                    Contract address:
                                </div>
                                <div className="col-9 wrap-address">
                                    {/* {details.contractAddress} */}
                                    {metadata.contract.address}  {/* temp hardcoding for stable demo */}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3 text-muted">
                                    Image address:
                                </div>
                                <div className="col-9 wrap-address">
                                    {/* {nftData.nftImage} */}
                                    {metadata.image.originalUrl} {/* temp hardcoding for stable demo */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * Returns a collapsible HTML section to display all the NFT data received from the Alchemy request.
     * @returns an HTML section to display all the data.
     */
    const renderAllNftData = () => {
        return (
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className={
                                isMetadataOpen
                                    ? "accordion-button"
                                    : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            onClick={toggleMetadata}
                        >
                            Metadata
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className={
                            isMetadataOpen
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                        }
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <div className="row mb-4">
                                <code>
                                    <pre>
                                        {JSON.stringify(
                                            // alchemyResponse,
                                            metadata, // temp hardcoding for stable demo
                                            null,
                                            4
                                        )}
                                    </pre>
                                </code>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        console.log(resultObject);
        if (resultObject.imageUrl) {
            setImageUrl(resultObject.imageUrl);
            setSvgData("");
        } else if (resultObject.svgData) {
            setSvgData(resultObject.svgData);
            setImageUrl("");
        } else {
            console.log("No image to display");
        }
    }, [resultObject]);

    console.log(resultObject);
    const alchemyResponse = resultObject.alchemyResponse;

    let metadata = resultObject["alchemyResponse"]?.raw.metadata;
    const contract = alchemyResponse?.contract;

    const nftData = {
        nftName: alchemyResponse?.name,
        collection: contract?.name,
        description: metadata?.description,
        nftImage: metadata?.image
            ? metadata?.image
            : resultObject.imageUrl
            ? resultObject.imageUrl
            : contract?.openSeaMetadata.imageUrl,
        imageIsSvg: resultObject.isSvg,
        imageStorageIsDecentralized: false,
        floorPrice: contract?.openSeaMetadata.floorPrice
            ? `${contract?.openSeaMetadata.floorPrice} ETH`
            : "N/A",
        contractAddress: contract?.address,
        metadataStorage:
            alchemyResponse?.gateway || alchemyResponse?.raw.metadata?.image,
        metadataStorageIsDecentralized: false,
        tokenId: alchemyResponse?.tokenId,
    };
    console.log(nftData);
    nftData.metadataStorageIsDecentralized = isIpfsUrl(nftData.metadataStorage)
        ? true
        : false;
    nftData.imageStorageIsDecentralized =
        isIpfsUrl(metadata?.image) ||
        (nftData.imageIsSvg && nftData.metadataStorageIsDecentralized)
            ? true
            : false;

    const details = {
        contractAddress: contract?.address,
    };
    console.log(metadata);
    if (typeof metadata === "undefined") {
        metadata = {
            contract: {
                address: "0x68e7F29a8Ac8a8dCB5E3C5f692cA291d2f65E386",
                tokenType: "ERC721",
                contractDeployer: "0xE47cfD37C23136e3a6927E85603faa85D78Fcc4B",
                deployedBlockNumber: 12366229,
                openSeaMetadata: {},
                spamClassifications: [],
            },
            tokenId: "0",
            tokenType: "ERC721",
            name: "Viola 41 Modello Capicchioni by Alessandro Milani",
            description:
                "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
            tokenUri:
                "https://alchemy.mypinata.cloud/ipfs/QmXgnk6nxadfmvLPnEZbsXJ8tMLe1qXv6APaQxRvPZCZ4E",
            image: {
                cachedUrl:
                    "https://nft-cdn.alchemy.com/matic-amoy/b77fae159308cd963b19c097566aa40b",
                thumbnailUrl:
                    "https://res.cloudinary.com/alchemyapi/image/upload/thumbnailv2/matic-amoy/b77fae159308cd963b19c097566aa40b",
                pngUrl: "https://res.cloudinary.com/alchemyapi/image/upload/convert-png/matic-amoy/b77fae159308cd963b19c097566aa40b",
                contentType: "image/png",
                size: 188812,
                originalUrl:
                    "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
            },
            raw: {
                tokenUri:
                    "https://ipfs.io/ipfs/QmXgnk6nxadfmvLPnEZbsXJ8tMLe1qXv6APaQxRvPZCZ4E",
                metadata: {
                    name: "Viola 41 Modello Capicchioni by Alessandro Milani",
                    description:
                        "A handcrafted 41 cm viola created by Alessandro Milani in 2024, inspired by a Marino Capicchioni model. The ownership of this NFT does not necessarily ensure the ownership of the instrument.",
                    image: "https://ipfs.io/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
                    attributes: [
                        {
                            value: "Viola",
                            trait_type: "Instrument",
                        },
                        {
                            value: "41 cm",
                            trait_type: "Size",
                        },
                        {
                            value: "2024",
                            trait_type: "Year of Construction",
                        },
                        {
                            value: "Boca (NO), Italy",
                            trait_type: "Place of Construction",
                        },
                        {
                            value: "Alessandro Milani",
                            trait_type: "Maker",
                        },
                        {
                            value: "Inspired by Marino Capicchioni",
                            trait_type: "Model",
                        },
                        {
                            value: "Italian spruce from Val di Fiemme",
                            trait_type: "Top Wood",
                        },
                        {
                            value: "Straight and regular grain, narrow at the center",
                            trait_type: "Grain Pattern",
                        },
                        {
                            value: "Balkan maple, two pieces",
                            trait_type: "Back Wood",
                        },
                        {
                            value: "Medium-width flamed, fades towards edges",
                            trait_type: "Flame Figure",
                        },
                        {
                            value: "Spirit varnish, bright slightly dark red, transparent",
                            trait_type: "Varnish",
                        },
                        {
                            value: "Woods seasoned for at least 8 years",
                            trait_type: "Natural Seasoning",
                        },
                        {
                            value: "Natural origin, extracted with Soxhlet apparatus",
                            trait_type: "Dyes",
                        },
                        {
                            value: "Rosewood, Padauk",
                            trait_type: "Colored Essences",
                        },
                    ],
                    external_url:
                        "https://amethyst-semantic-clam-109.mypinata.cloud/ipfs/QmZdBv7EeqvmrEc8kzyY99jNELRrMdDZU6Zd48jCKUqgjX",
                },
            },
            mint: {},
            timeLastUpdated: "2024-09-24T15:23:45.618Z",
        };
    }
    return (
        <div className="Display">
            <div className="report-container">
                {(() => {
                    if (Object.keys(metadata).length !== 0) {
                        return (
                            <>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="center">
                                                {/* {nftData.nftName} */}
                                                {metadata.name} {/* temp hardcoding for stable demo */}
                                            </h1>
                                            {/* <h2>{nftData.collection}</h2> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            {/* {imageUrl
                                                ? renderImage(imageUrl)
                                                : ""}
                                            {svgData ? renderSvgImage() : ""} */}
                                            { renderImage(imageUrl) } {/* temp hardcoding for stable demo */}   
                                        </div>
                                        <div className="col-md-7">
                                            {renderNftMainData()}
                                            {renderPassport()}
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {renderNftDetails()}
                                            {renderAllNftData()}
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    }
                })()}
            </div>
        </div>
    );
};

Display.propTypes = {};

Display.defaultProps = {};

export default Display;
