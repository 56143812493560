/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./Solutions.scss";

const Solutions = () => (
    <div className="Solutions">
        <h1>Solutions</h1>
        <h3>This page is under construction</h3>
        <p>
            While we are preparing more content, you can find more information
            on <a href="/">Home page</a>
        </p>
    </div>
);

Solutions.propTypes = {};

Solutions.defaultProps = {};

export default Solutions;
