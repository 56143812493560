/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./Impressum.scss";

const Impressum = () => (
    <div className="Impressum">
        <div class="container">
            <h1>Impressum</h1>

            <div class="section">
                <h2>Company Information</h2>
                <p>
                    <strong>Beligent</strong> (Beligent, Inh. Perrin)
                    <br />
                    Zurich
                    <br />
                    Switzerland
                </p>
            </div>

            <div class="section">
                <h2>Contact</h2>
                <ul class="contact-info">
                    <li>Phone: [Phone Number]</li>
                    <li>
                        Email:{" "}
                        <span className="">contact(at)beligent.io</span>
                    </li>
                    <li>
                        Website:{" "}
                        <a href="[https://beligent.io]" target="_blank">
                            beligent.io
                        </a>
                    </li>
                </ul>
            </div>

            <div class="section">
                <h2>Managing Director</h2>
                <p>Vincent Perrin</p>
            </div>

            <div class="section">
                <h2>Commercial Register</h2>
                <p>Registered in the Commercial Register of Zurich</p>
                <p>Registration Number: CHE-207.078.027</p>
            </div>

            <div class="section">
                <h2>Disclaimer</h2>
                <p>
                    The information on this website is provided for general
                    information purposes only. Beligent assumes no liability for
                    the completeness or accuracy of the information provided.
                </p>
                <p>
                    Links to external websites are provided as a service.
                    Beligent assumes no responsibility for the content of these
                    external websites.
                </p>
            </div>
        </div>
    </div>
);

Impressum.propTypes = {};

Impressum.defaultProps = {};

export default Impressum;
