/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./MultiFormContainer.scss";

import LoginForm from "../../UserOnboarding/LoginForm/LoginForm.js";
import { NavLink } from "react-router-dom";

const MultiFormContainer = ({ recordUser }) => {
    const [questionnaireType, setQuestionnaireType] = useState("login");
    console.log("questionnaireType: ", questionnaireType);

    // Execute different queries based on the current state
    if (questionnaireType === "login") {
        console.log("Executing login query...");
    } else if (questionnaireType === "register") {
        console.log("Executing register query...");
    } else if (questionnaireType === "forgot-password") {
        console.log("Executing forgot password query...");
    }

    const handleQuestionnaireChange = (event, choice) => {
        event.preventDefault(); // Prevent page reload for links
        setQuestionnaireType(choice); // Update the state
    };

    return (
        <div className="MultiFormContainer">
            <div className="questionnaire-container">
                <div className="questionnaire">
                    <h2>
                        {questionnaireType === "login" && "Login"}
                        {questionnaireType === "register" && "Register"}
                        {questionnaireType === "forgot-password" &&
                            "Forgot Password"}
                    </h2>
                    {process.env.NODE_ENV !== "production" && (
                        <nav className="links">
                            <span className="nav-item">
                                <a
                                    href="#"
                                    className={
                                        questionnaireType === "login"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={(e) =>
                                        handleQuestionnaireChange(e, "login")
                                    }
                                >
                                    Login
                                </a>
                            </span>
                            <span className="nav-item">
                                <a
                                    href="#"
                                    className={
                                        questionnaireType === "register"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={(e) =>
                                        handleQuestionnaireChange(e, "register")
                                    }
                                >
                                    Register
                                </a>
                            </span>
                            <span className="nav-item">
                                <a
                                    href="#"
                                    className={
                                        questionnaireType === "forgot-password"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={(e) =>
                                        handleQuestionnaireChange(
                                            e,
                                            "forgot-password"
                                        )
                                    }
                                >
                                    Forgot Password
                                </a>
                            </span>
                        </nav>
                    )}
                    <LoginForm
                        recordUser={recordUser}
                        questionnaireType={questionnaireType}
                    />
                    <p className="no-account">
                        Not having an account?
                        <br />Contact us at contact(at)beligent.io.
                    </p>
                    {process.env.NODE_ENV !== "production" && (
                        <nav className="links">
                            <span className="nav-item">
                                <NavLink to="/onboarding/registration-end">
                                    Registration End
                                </NavLink>
                            </span>
                        </nav>
                    )}
                </div>
            </div>
        </div>
    );
};

MultiFormContainer.propTypes = {};

MultiFormContainer.defaultProps = {};

export default MultiFormContainer;
