/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./PassportForge.scss";
import axios from "axios";

const endpointUrl = process.env.REACT_APP_URL_ENDPOINTS;

const PassportForge = ({ user }) => {
    // const certificateContractAbi = abiPassportForge.abi;
    let certificateContract;
    let provider;
    let signer;

    const passportCreatorName = "Beligent";

    const [passportIssuerAddress, setVerifiedNftIssuerAddress] = useState("");
    const [passportIssuerName, setVerifiedNftIssuerName] = useState("");
    const [passportTimestamp, setVerifiedNftCertificateTimestamp] =
        useState("");

    const [passportDone, setPassportDone] = useState(false);
    const [passportInProgress, setPassportInProgress] = useState(false);
    const [txnHash, setTxnHash] = useState("");
    const smartContractAddress = "0x893ab39bd7f4b8b4b6b6b6b6b6b6b6b6b6b6b6b6";

    /* Code for when we want to store further than the component's life time
    // local state is initialized by local storage
    const [certificateDone, setCertificateDone] = useState(() => {
        const saved = localStorage.getItem("_BLGT_certificateDone");
        return saved ? JSON.parse(saved) : false;
    });
    // Local storage updates when component state is updated
    useEffect(() => {
        localStorage.setItem(
            "_BLGT_certificateDone",
            JSON.stringify(certificateDone)
        );
    }, [certificateDone]);
    */

    /*** METHODS *************************************************************/

    /**
     * Listens in for event
     *
    const smartContractListener = async () => {
        if (ethereum) {
            if (!passportDone && !passportInProgress) {
                console.log("Waiting for certificate");
                // if (connectedToMintingContract) {
                //     setConnectedToMintingContract(true);
                // const onCertificateHasBeenRecorded = (from, timestamp, message) => {
                //     console.log("CertificateHasBeenRecorded", from, timestamp, message);
                // };

                console.log("ethereumethereum");
                provider = new ethers.providers.Web3Provider(ethereum);
                signer = provider.getSigner();

                console.log("signer useEffect");
                console.log(signer);
                certificateContract = new ethers.Contract(
                    process.env.REACT_APP___DEPRECATED__CERTIFICATE_CONTRACT_ADDRESS!,
                    certificateContractAbi,
                    signer
                );

                // Certificate hook indicates positive transaction record
                certificateContract.on(
                    "CertificateHasBeenRecorded",
                    (from, creatorName, timestamp) => {
                        console.log("CertificateHasBeenRecorded event received");
                        console.log(from, creatorName, timestamp);

                        setVerifiedNftIssuerAddress(from);
                        setVerifiedNftIssuerName(creatorName);

                        var dateFormat = new Date(timestamp.toNumber() * 1000);
                        setVerifiedNftCertificateTimestamp(
                            dateFormat.toLocaleDateString() +
                                " " +
                                dateFormat.toLocaleTimeString()
                        );

                        setPassportInProgress(false);
                        setPassportDone(true);
                    }
                );
            }
        } else {
            console.log("Ethereum object doesn't exist!");
        }
    };

    const renderSeeNft = () => (
        <div>
            <p>The NFT has been minted and sent to your wallet.</p>
            <p>
                See it on OpenSea:
                <a
                    href={`https://testnets.opensea.io/assets/sepolia/${collectionId}/${nftId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    https://testnets.opensea.io/assets/sepolia/{collectionId}/{nftId}
                </a>
                <br></br>
                Or on&nbsp;
                <a
                    href={`https://sepolia.etherscan.io/nft/${collectionId}/${nftId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    etherscan
                </a>.
            </p>
        </div>
    );
*/

    /*
    const onMintedCallback = (collectionId: string, tokenId: string) => {
        console.log("onMintedCallback");
        console.log("tokenId", tokenId);
        console.log("collectionId", collectionId);
        setMinted(true);
        setTokenId(tokenId);
        setCollectionId(collectionId);
        console.log(user?.publicAddress);
    };

    useEffect(() => {
        const { ethereum } = window;

        if (!ethereum) {
            console.log("Make sure you have metamask!");
            return;
        } else {
            // console.log("We have the ethereum object", ethereum);
        }
    }, []);
*/
    const renderSeeTransaction = () => (
        <div>
            <p>
                Transaction on etherscan:&nbsp;
                <a
                    href={`https://sepolia.etherscan.io/tx/${txnHash}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {txnHash}
                </a>
            </p>
        </div>
    );

    /**
     * Makes an hard-coded request to the smart contract
     */
    const makeCertificate = async () => {
        console.log("-> makeCertificate");

        setPassportInProgress(true);

        console.log("axios.post, endpointUrl:", endpointUrl);
        // Make a POST request to the backend /login endpoint
        const response = await axios.post(
            endpointUrl + "certificate",
            // "http://localhost:3001/certificate",
            {}
        );
        console.log("response", response);
        console.log("response.data", response.data);
        console.log(
            "data.transactionReceipt.blockHash",
            response.data.transactionReceipt.blockHash
        );
        setTxnHash(response.data.transactionReceipt.transactionHash);

        setPassportInProgress(false);
        setPassportDone(true);
    };

    /*** RENDERING ***********************************************************/
    const renderWelcomeMessage = () => (
        <div className="welcome-message">
            <h2>PassportForge</h2>
            {/* <p>Welcome {user["firstName"]}</p> */}
        </div>
    );

    const renderResultSuccessMessage = () => (
        <div className="section-content">
            <h3>Your Digital Product Passport is ready</h3>
            <div className="certificate-preview">
                <span>Issuer address: </span>
                <span>{passportIssuerAddress}</span>
                <br></br>
                <span>Issuer name: </span>
                <span>{passportIssuerName}</span>
                <br></br>
                <span>Certificate timestamp: </span>
                <span>{passportTimestamp}</span>
                <br></br>
            </div>
            CheckApp it on the <a href="./../check/">Validator</a> (
            <a
                href={`./../check/?address=${encodeURIComponent(
                    smartContractAddress
                )}&tokenId=${encodeURIComponent(smartContractAddress)}`}
                target="_blank"
            >
                new page
            </a>
            ).
        </div>
    );

    const renderCertificateMakingButton = () => (
        <>
            <button
                onClick={makeCertificate}
                className="btn action-button mint-button + ('disable-button')"
                //disabled
            >
                Create a certificate
            </button>
            <br></br>
            With {passportCreatorName} as Passport creator.
        </>
    );

    const renderInProgress = () => (
        <>
            <div>
                <img src="./progress.gif" width="200px" />
            </div>
            <div>The Passport is being recorded on the blockchain...</div>
        </>
    );

    return (
        <>
            {renderWelcomeMessage()}
            {(() => {
                if (!passportInProgress && !passportDone) {
                    return <>{renderCertificateMakingButton()}</>;
                } else {
                    if (!passportInProgress) {
                        return <>Available after minting the NFT.</>;
                    }
                }
            })()}

            {(() => {
                if (passportInProgress && !passportDone) {
                    return <>{renderInProgress()}</>;
                }
            })()}

            {(() => {
                if (passportDone) {
                    return (
                        <>
                            {renderResultSuccessMessage()}
                            <br />
                            {renderSeeTransaction()}
                        </>
                    );
                }
            })()}
        </>
    );
};

PassportForge.propTypes = {};

PassportForge.defaultProps = {};

export default PassportForge;
