/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./ContactUs.scss";

import ContactButton from "../../ContactButton/ContactButton";

const ContactUs = () => (
    <div className="ContactUs">
        <h1>Contact Us</h1>
        <p>
            <br />
            We are glad to see you visiting Beligent's website while it is under
            construction.
            <br />
            <br />
            The best way to contact us is for now to send us an email at{" "}
            <a href="#" className="email">contact(at)beligent.io</a>.
        </p>
        <br />
        <div>
            <ContactButton></ContactButton>
            Click to copy our email
        </div>
    </div>
);

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
