/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./Dashboard.scss";

const Dashboard = () => (
    <div className="Dashboard">
        <h1 className="">Certificate platform</h1>
        <br />
        The dashboard is empty for now
    </div>
);

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
