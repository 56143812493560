/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";
import "./Platform.scss";
import MultiFormContainer from "../../UserOnboarding/MultiFormContainer/MultiFormContainer.js";

import PassportForge from "../PassportForge/PassportForge.tsx";
import Sidebar from "../Sidebar/Sidebar.js";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard"; // Add this line to import Dashboard
import PassportRepository from "../PassportRepository/PassportRepository.js";
import UserSettings from "../UserSettings/UserSettings.js";

const menuItems = [
    { icon: "🏠", title: "Dashboard", path: "/platform/dashboard" },
    {
        icon: "⚒️",
        title: "Passport Forge",
        path: "/platform/passport-forge",
    },
    {
        icon: "📄",
        title: "Passport repository",
        path: "/platform/passport-repository",
    },
    { icon: "⚙️", title: "User Settings", path: "/platform/user-settings" },
];

const Platform = ({ user }) => {
    console.log("platform Component");

    const location = useLocation();
    console.log("Current Route:", location.pathname);

    // const [tokenId, setTokenId] = useState("");
    // const [collectionId, setCollectionId] = useState("");

    // local state is initialized by local storage
    // const [connectedToMintingContract, setConnectedToMintingContract] =
    //     useState(() => {
    //         const saved = localStorage.getItem(
    //             "_BLGT_connectedToMintingContract"
    //         );
    //         return saved ? JSON.parse(saved) : false;
    //     });
    // // Local storage updates when component state is updated
    // useEffect(() => {
    //     localStorage.setItem(
    //         "_BLGT_connectedToMintingContract",
    //         JSON.stringify(connectedToMintingContract)
    //     );
    // }, [connectedToMintingContract]);

    const recordUser = (loggedInUser) => {
        console.log("recordUser: ");
        console.log(loggedInUser);
        // Callback for successful login from popup
        // setUser(loggedInUser);
        user = loggedInUser;
        saveUserToSession(loggedInUser);
    };

    const saveUserToSession = (user) => {
        const timestamp = new Date().getTime(); // Get current time in milliseconds
        localStorage.setItem("_BLGT_user", JSON.stringify(user)); // Store user object
        localStorage.setItem("_BLGT_userTimestamp", timestamp.toString()); // Store timestamp
    };

    const checkSessionTimeout = () => {
        console.log("Checking session timeout");
        const user = JSON.parse(localStorage.getItem("_BLGT_user"));
        const timestamp = localStorage.getItem("_BLGT_userTimestamp");

        if (!user || !timestamp) {
            // If no user or timestamp exists, session is invalid
            return null;
        }

        const currentTime = new Date().getTime();
        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

        if (currentTime - timestamp > TEN_MINUTES) {
            // if (currentTime - Number.parseFloat(timestamp) > TEN_MINUTES) {
            // If more than 10 minutes have passed, clear the session
            localStorage.removeItem("_BLGT_user");
            localStorage.removeItem("_BLGT_userTimestamp");
            console.log("Session expired");
            return null;
        }

        // If session is still valid, return the user
        return user;
    };
    // useEffect(() => {
    //     const savedUser = checkSessionTimeout();
    //     if (savedUser) {
    //         setUser(savedUser);
    //     }
    // }, []);

    const renderLoginForm = () => {
        console.log("renderLoginForm user: ");
        console.log(user);
        if (!user) {
            return (
                <div className="hero gradient-background white-font">
                    <div className="text-box">
                        <div>
                            <h1>Digital Product Passport</h1>
                            <h1 className="subtitle">
                                Your journey of innovation begins here.
                            </h1>
                            <hr />
                            <p className="main-description">
                                Login and start creating now!
                            </p>
                        </div>
                    </div>
                    <MultiFormContainer recordUser={recordUser} />
                </div>
            );
        }
    };

    const renderPassportForgeComponent = () => {
        console.log("renderPassportForgeComponent user: ");
        console.log(user);
        if (!!user) {
            return (
                <>
                    <div className="layout-container">
                        <aside className="sidebar">
                            <Sidebar menuItems={menuItems} />
                        </aside>
                        <main className="content">
                            <Outlet />
                        </main>
                    </div>
                </>
            );
        }
    };

    function PlatformLayout() {
        console.log("user: ");
        console.log(user);
        return (
            <div className="Platform">
                <div className="platform-container">
                    {renderLoginForm()}
                    {renderPassportForgeComponent()}
                </div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<PlatformLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                    path="/passport-forge"
                    element={<PassportForge user={user} />}
                />
                <Route
                    path="/passport-repository"
                    element={<PassportRepository />}
                />
                <Route path="/user-settings" element={<UserSettings />} />
                <Route
                    index
                    element={<h1>Welcome to default platform page</h1>}
                />
            </Route>
        </Routes>
    );
};

Platform.propTypes = {};

Platform.defaultProps = {};

export default Platform;
