/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LoginForm.scss";
import { validateEmail } from "../../../lib/utils";

const LoginForm = ({ recordUser, questionnaireType }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);
    const endpointUrl = process.env.REACT_APP_URL_ENDPOINTS;

    //////////////////////////////////////////////////////////////
    // Initialize the user state with data from localStorage
    const [user, setUser] = useState(() => {
        const saved = localStorage.getItem("_BLGT_user");
        return saved ? JSON.parse(saved) : "";
    });
    useEffect(() => {
        localStorage.setItem("_BLGT_user", JSON.stringify(user));
    }, [user]);
    ////////////////////////////////////////////////////////////// should put the following back in
    const [jwt, setJwt] = useState(() => {
        const saved = localStorage.getItem("_BLGT_jwt");
        console.log("saved:", saved);
        return saved ? (saved !== undefined || JSON.parse(saved)) : "";
    });
    // Keeps local storage in sync with component state
    useEffect(() => {
        localStorage.setItem("_BLGT_jwt", JSON.stringify(jwt));
    }, [jwt]);
    //////////////////////////////////////////////////////////////

    // const [user, setUser] = useState(null);
    // const recordUser = (loggedInUser) => {
    //     console.log("recordUser: ");
    //     console.log(loggedInUser);
    //     // Callback for successful login from popup
    //     setUser(loggedInUser);
    //     saveUserToSession(loggedInUser);
    // };

    // const saveUserToSession = (user) => {
    //     const timestamp = new Date().getTime(); // Get current time in milliseconds
    //     localStorage.setItem("_BLGT_user", JSON.stringify(user)); // Store user object
    //     localStorage.setItem("_BLGT_userTimestamp", timestamp); // Store timestamp
    // };

    // Handler for form submission
    const handleLogin = async (e) => {
        // console.log("handleLogin:", e);
        e.preventDefault(); // Prevent default form submission behavior that reloads the page
        setLoading(true);
        setServerError(null);

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email.");
            setLoading(false);
            return;
        } else {
            setEmailError("");
        }

        let queryPath = "";
        let parameters = {};

        // Execute different queries based on the current state
        if (questionnaireType === "login") {
            // console.log("Executing login query...");
            queryPath = endpointUrl + "auth/login";
            // "http://localhost:3001/auth/login",
            parameters = {
                email,
                password,
            };
        } else if (questionnaireType === "register") {
            // console.log("Executing register query...");
            queryPath = endpointUrl + "auth/register";
            parameters = {
                email,
                password,
            };
        } else if (questionnaireType === "forgot-password") {
            // console.log("Executing forgot password query...");
            queryPath = endpointUrl + "auth/forgot-password";
            parameters = {
                email
            };
        }

        setLoading(true);
        try {
            console.log("axios.post, endpointUrl:", endpointUrl);
            // Make a POST request to the backend /login endpoint
            const response = await axios.post(
                queryPath,
                // {
                //     // TODO port in variable and switch depending on prod mode
                //     email,
                //     password,
                // },
                parameters,
                {
                    withCredentials: true, // Ensure cookies are sent
                }
            );

            /*
                "Invalid credentials"	Incorrect email or password.	401 Unauthorized
                "Email and password are required"	Missing credentials in the request.	400 Bad Request
                "User not found"	Email does not match any user in the system.	404 Not Found
                "Token expired"	Authentication token has expired.	401 Unauthorized
                "Invalid token"	Provided token is invalid or tampered with.	401 Unauthorized
                "Internal server error"	Unexpected error occurred during authentication.	500 Internal Server Error
            */

            console.log("Login successful:", response.data);

            setJwt(response.data.token);
            setUser(response.data.user);
            recordUser(response.data.user);

            setLoading(false);

            // Need this to refresh the page after login
            window.location.reload();
        } catch (err) {
            const errorCode = err.status;
            const errorResponse = err.response;
            console.log("error:", errorResponse);

            switch (errorCode) {
                case 400:
                    // setServerError("Email and password are required.");
                    setServerError("Invalid credentials.");
                    break;
                case 401:
                    setServerError("Invalid credentials.");
                    break;
                case 404:
                    // setServerError("User not found.");
                    setServerError("Invalid credentials.");
                    break;
                case 500:
                    setServerError("Internal server error.");
                    setServerError("Invalid credentials."); // temp until server fully functional
                    break;
                default:
                    // setServerError("An unexpected error occurred.");
                    setServerError("Invalid credentials."); // temp until server fully functional
                    break;
            }
        } finally {
            setLoading(false);
        }
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    // };
    const getButtonText = (loading, questionnaireType) => {
        if (loading) return "Loading...";
        if (questionnaireType === "login") return "Login";
        if (questionnaireType === "register") return "Register";
        return "Reset Password";
    };

    return (
        <div className="LoginForm">
            <form onSubmit={handleLogin}>
                <div className="form-group">
                    <span>Email:</span>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="form-group">
                    <span>Password:</span>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button
                    className="btn action-button"
                    onClick={handleLogin}
                    disabled={loading}
                >
                    {getButtonText(loading, questionnaireType)}
                </button>
            </form>
            {serverError && <p className="error">{serverError}</p>}
        </div>
    );
};

LoginForm.propTypes = {};

LoginForm.defaultProps = {};

export default LoginForm;
