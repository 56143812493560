/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./UserSettings.scss";

const UserSettings = () => (
    <div className="UserSettings">There is no user settings yet.</div>
);

UserSettings.propTypes = {};

UserSettings.defaultProps = {};

export default UserSettings;
