/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./KycForm.scss";
import { NavLink } from "react-router-dom";

const KycForm = ({ user }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
        }

        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }

        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        } else if (formData.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            alert("Form submitted successfully!");
            // Handle form submission logic here
        }
    };

    return (
        <div className="KycForm hero gradient-background white-font">
            <div className="onboarding-text">
            <h2>KycForm Component</h2>
            <span className="nav-item">
                <NavLink to="/onboarding/subscripbtion-selection">
                    Subscription Selection
                </NavLink>
            </span>
            </div>

            <div className="container">
                <div className="end-registration-form">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="input-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="input"
                            />
                            {errors.name && (
                                <span className="error">{errors.name}</span>
                            )}
                        </div>

                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="input"
                            />
                            {errors.email && (
                                <span className="error">{errors.email}</span>
                            )}
                        </div>

                        <div className="input-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className="input"
                            />
                            {errors.password && (
                                <span className="error">{errors.password}</span>
                            )}
                        </div>

                        <button type="submit" className="btn action-button">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

KycForm.propTypes = {};

KycForm.defaultProps = {};

export default KycForm;
