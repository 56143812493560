/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import "./AppFooter.scss";
import { NavLink } from "react-router-dom";

const AppFooter = () => {
    // if (process.env.NODE_ENV !== "production") {
        return (
            <footer className="AppFooter">
                <div className="normal-layout">
                    <div className="footer-left">
                        <div className="text section">
                            <span className="bold">Beligent </span> ©2025
                            <br />
                            Zurich, Switzerland
                        </div>

                        <div className="logo-container">
                            <img
                                src="/logo-small.png"
                                className="img-fluid "
                                alt="Beligent"
                            />
                        </div>
                    </div>
                    <div className="vertical-bar"></div>
                    <div className="footer-right">
                        <div className="section">
                            <h4>Sitemap</h4>
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Main Site
                            </NavLink>                            
                            {/* This is to come after product. 
                             <NavLink
                                to="/check"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Passport viewer
                            </NavLink> */}
                            <NavLink
                                to="/platform"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Platform
                            </NavLink>                            
                            {/* <NavLink
                                to="/documentation"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Documentation
                            </NavLink> */}
                        </div>
                        <div className="section">
                            <h4>Legal</h4>
                            <NavLink
                                to="/impressum"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Impressum
                            </NavLink>
                            <NavLink
                                to="/terms-of-use"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Terms of Use
                            </NavLink>
                            <NavLink
                                to="/privacy-policy"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Privacy Policy
                            </NavLink>
                        </div>
                        <div className="section">
                            <h4>Contact</h4>
                            <NavLink
                                to="/contact-us"
                                className={({ isActive }) =>
                                    isActive
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                            >
                                Contact Us
                            </NavLink>
                        </div>
                    </div>

                </div>

            </footer>
        );
    // } else {
    //     return (
    //         <footer className="AppFooter">
    //             <div className="normal-layout">
    //                 <div className="menu-container">
    //                     <span className="bold">Contact Us:</span>
    //                     <br />
    //                     contact(at)beligent.io
    //                 </div>
    //                 <div className="right-side">
    //                     <div className="company-section">
    //                         <span className="bold">Beligent </span> ©2024
    //                         <br />
    //                         Zurich, Switzerland
    //                     </div>
    //                     <div className="logo-container">
    //                         <img
    //                             src="logo-small.png"
    //                             className="img-fluid "
    //                             alt="Beligent"
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="mobile-layout">
    //                 <div className="left-side">
    //                     <div className="menu-container">
    //                         <p>
    //                             <span className="">contact(at)beligent.io</span>
    //                             <br />
    //                             <span className="bold">Beligent </span> ©2024
    //                             <br />
    //                             Zurich, Switzerland
    //                         </p>
    //                     </div>
    //                 </div>
    //                 <div className="logo-container">
    //                     <img
    //                         src="logo-small.png"
    //                         className="img-fluid "
    //                         alt="Beligent"
    //                     />
    //                 </div>
    //             </div>
    //         </footer>
    //     );
    // }
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
