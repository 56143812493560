/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";
import "./Onboarding.scss";
// import MultiFormContainer from "../../UserOnboarding/MultiFormContainer/MultiFormContainer.js";

// import PassportForge from "../../platform/PassportForge/PassportForge.tsx";
// import Sidebar from "../Sidebar/Sidebar.js";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
// import Dashboard from "../Dashboard/Dashboard"; // Add this line to import Dashboard
// import PassportRepository from "../PassportRepository/PassportRepository.js";
// import UserSettings from "../UserSettings/UserSettings.js";
import SubscriptionSelection from "../SubscriptionSelection/SubscriptionSelection.js";
import SubscriptionPayment from "../SubscriptionPayment/SubscriptionPayment.js";
import KycForm from "../KycForm/KycForm.js";
import RegistrationEnd from "../RegistrationEnd/RegistrationEnd.js";

const Onboarding = ({ user }) => {
    function OnboardingLayout() {
        console.log("user: ");
        console.log(user);
        return (
            <div className="Onboarding">
                <Outlet />
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<OnboardingLayout />}>
                <Route path="/registration-end" element={<RegistrationEnd />} />
                <Route
                    path="/kyc-verification"
                    element={<KycForm user={user} />}
                />
                <Route
                    path="/subscripbtion-selection"
                    element={<SubscriptionSelection user={user} />}
                />
                <Route
                    path="/subscription-payment"
                    element={<SubscriptionPayment user={user} />}
                />
                <Route
                    index
                    element={<h1>Welcome to default platform page</h1>}
                />
            </Route>
        </Routes>
    );
};

Onboarding.propTypes = {};

Onboarding.defaultProps = {};

export default Onboarding;
