/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import PropTypes from "prop-types";
import "./PassportRepository.scss";

const PassportRepository = () => (
    <div className="PassportRepository">There is no passport display yet.</div>
);

PassportRepository.propTypes = {};

PassportRepository.defaultProps = {};

export default PassportRepository;
