/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LoginPopup.scss";
import { validateEmail } from "../../../lib/utils";

const LoginPopup = ({ closePopup, recordUser }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);
    const endpointUrl = process.env.REACT_APP_URL_ENDPOINTS;

    //////////////////////////////////////////////////////////////
    // Initialize the user state with data from localStorage
    const [user, setUser] = useState(() => {
        const saved = localStorage.getItem("_BLGT_user");
        return saved ? JSON.parse(saved) : "";
    });
    useEffect(() => {
        localStorage.setItem("_BLGT_user", JSON.stringify(user));
    }, [user]);
    //////////////////////////////////////////////////////////////
    const [jwt, setJwt] = useState(() => {
        const saved = localStorage.getItem("_BLGT_jwt");
        return saved ? JSON.parse(saved) : "";
    });
    // Keeps local storage in sync with component state
    useEffect(() => {
        localStorage.setItem("_BLGT_jwt", JSON.stringify(jwt));
    }, [jwt]);
    //////////////////////////////////////////////////////////////

    const handleOverlayClick = (e) => {
        if (e.target.className.includes("popup-overlay")) {
            closePopup();
        }
    };


    // State for handling loading and error messages

    // Handler for form submission
    const handleLogin = async (e) => {
        // console.log("handleLogin:", e);
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true);
        setServerError(null);

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email.");
            return;
        } else {
            setEmailError("");
        }

        setLoading(true);
        try {
            console.log("axios.post, endpointUrl:", endpointUrl);
            // Make a POST request to the backend /login endpoint
            const response = await axios.post(endpointUrl + "auth/login",
               // "http://localhost:3001/auth/login",
                {
                    // TODO port in variable and switch depending on prod mode
                    email,
                    password,
                }, {
                    withCredentials: true, // Ensure cookies are sent
                }
            );

            /*
                "Invalid credentials"	Incorrect email or password.	401 Unauthorized
                "Email and password are required"	Missing credentials in the request.	400 Bad Request
                "User not found"	Email does not match any user in the system.	404 Not Found
                "Token expired"	Authentication token has expired.	401 Unauthorized
                "Invalid token"	Provided token is invalid or tampered with.	401 Unauthorized
                "Internal server error"	Unexpected error occurred during authentication.	500 Internal Server Error
            */

            console.log("Login successful:", response.data);

            setJwt(response.data.token);
            setUser(response.data.user);
            recordUser(response.data.user);

            setLoading(false);
            closePopup();
        } catch (err) {
            const errorCode = err.status;
            const errorResponse = err.response;
            console.log("error:", errorResponse);
            
            switch (errorCode) {
                case 400:
                    // setServerError("Email and password are required.");
                    setServerError("Invalid credentials.");
                    break;
                case 401:
                    setServerError("Invalid credentials.");
                    break;
                case 404:
                    // setServerError("User not found.");
                    setServerError("Invalid credentials.");
                    break;
                case 500:
                    setServerError("Internal server error.");
                    setServerError("Invalid credentials."); // temp until server fully functional
                    break;
                default:
                    // setServerError("An unexpected error occurred.");
                    setServerError("Invalid credentials."); // temp until server fully functional
                    break;
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="LoginPopup" onClick={handleOverlayClick}>
            <div
                className="LoginPopup popup-overlay"
                onClick={handleOverlayClick}
            >
                <div className="popup">
                    <button className="close-btn" onClick={closePopup}>
                        X
                    </button>
                    <h2>Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <span>Email:</span>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {emailError && (
                                <p className="error">{emailError}</p>
                            )}
                        </div>
                        <div className="form-group">
                            <span>Password:</span>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button className="btn action-button" onClick={handleLogin} disabled={loading}>
                            {loading ? "Loading..." : "Login"}
                        </button>
                    </form>
                    {serverError && <p className="error">{serverError}</p>}
                </div>
            </div>
        </div>
    );
};

LoginPopup.propTypes = {};

LoginPopup.defaultProps = {};

export default LoginPopup;
